import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query ToolQuery($id: ID!) {
    strapi {
      tool: kongsbergTool(id: $id) {
        slug
        name
        description
        image {
          id
          alternativeText
          staticFile {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: BLURRED)
            }
          }
        }
        materials {
          id
          name
        }
        tables {
          id
          name
          slug
        }
        toolType {
          id
          name
        }
      }
    }
  }
`

const ToolTemplate = ({
  data: {
    strapi: { tool },
  },
}) => {
  return (
    <Layout>
      <SEO title={tool.name} />
      <div className="py-32 container mx-auto">
        <div className="grid max-w-5xl mx-auto grid-cols-1 md:grid-cols-2 gap-6">
          <div className="order-last md:order-first">
            {tool.image?.staticFile && (
              <GatsbyImage
                image={getImage(tool.image.staticFile)}
                alt={tool.image.alternativeText}
              />
            )}
          </div>
          <div className="mb-6">
            <p className="text-sm font-semibold text-brand-500">
              {tool.toolType.name}
            </p>
            <h1 className="heading-lg">{tool.name}</h1>
            <p className="mt-6 text-xs text-gray-500 font-semibold uppercase">
              Beskrivelse
            </p>
            <p>{tool.description}</p>

            <p className="mt-6 text-xs text-gray-500 font-semibold uppercase">
              Skærebord
            </p>
            {tool.tables.map(table => (
              <Link
                key={table.id}
                to={`/${table.slug}`}
                className="inline-block text-sm py-0.5 px-2 rounded-sm mr-1 bg-brand-100 text-brand-800 hover:bg-brand-500 hover:text-brand-50"
              >
                {table.name}
              </Link>
            ))}

            <p className="mt-6 text-xs text-gray-500 font-semibold uppercase">
              Materialer
            </p>
            {tool.materials.map(material => (
              <span
                key={material.id}
                className="inline-block text-sm py-0.5 px-2 rounded-sm bg-gray-100 text-gray-500 mr-1"
              >
                {material.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ToolTemplate
